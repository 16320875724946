const api = `https://api.groupfi.ai`
const tokenAddress = '0x30d2422A2cD8471e19867Bc41E76AeF6e0044A30'
const get = async (url) => {
    const response = await fetch(`${api}/${url}`, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
    return response.json()
}

// tanglepay,metamask,okx,
// 判断是否安装tp钱包
window.onload = async () => {
    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))
    const connectDom = document.getElementById('connect-con')
    window.hideConnect = () => {
        connectDom.classList.add('hidden')
    }
    window.showConnect = () => {
        connectDom.classList.remove('hidden')
    }
    const storageKey = 'groupfi-wallet-info'
    function getWalletInfoFromStorage() {
        const walletInfoStr = window.localStorage.getItem(storageKey)
        if (walletInfoStr) {
            return JSON.parse(walletInfoStr)
        }
        return null
    }
    function storeWalletInfo(walletInfo) {
        localStorage.setItem(storageKey, JSON.stringify(walletInfo))
    }
    function clearWalletInfo() {
        walletInfo = null
        localStorage.removeItem(storageKey)
    }
    let walletInfo = getWalletInfoFromStorage()
    if (!walletInfo?.walletType) {
        walletInfo = null
    }
    const setAddress = function (walletInfo) {
        const address = walletInfo?.address
        var dom0 = document.getElementById('connect-btn')
        var dom1 = document.getElementById('has-connect-btn')
        if (address) {
            if (!dom0.classList.contains('hidden')) {
                dom0.classList.add('hidden')
            }
            if (dom1.classList.contains('hidden')) {
                dom1.classList.remove('hidden')
            }
            dom1.querySelector('.address').innerText = address.replace(/(^.{4})(.+)(.{4}$)/, '$1...$3')
        } else {
            if (!dom1.classList.contains('hidden')) {
                dom1.classList.add('hidden')
            }
            if (dom0.classList.contains('hidden')) {
                dom0.classList.remove('hidden')
            }
        }
        if (walletInfo) {
            storeWalletInfo(walletInfo)
        } else {
            clearWalletInfo()
        }
    }
    const setWalletItemDisabled = (walletName) => {
        const dom = document.getElementById(walletName)
        const span = dom.querySelector('span')
        dom.classList.add('disabled')
        const str = span.innerText
        span.innerText = str + ' (not detected)'
    }
    /*-------------------------------*/
    // tanglepay
    // if (!window.iota?.isTanglePay) {
    //     setWalletItemDisabled('tanglepay')
    // }
    // metamask
    const MMSDK = new MetaMaskSDK.MetaMaskSDK({
        dappMetadata: {
            name: 'groupfi.ai',
            url: window.location.href
        },
        extensionOnly: true
        // Other options.
    })
    await sleep(0)
    if (!MMSDK.getProvider()) {
        setWalletItemDisabled('metamask')
    }
    // okx
    if (!window.okxwallet?.isOkxWallet && !window.okxwallet?.isOKExWallet) {
        setWalletItemDisabled('okx')
    }
    // coinbase
    // if (!window.CoinbaseWalletSDK) {
    //     setWalletItemDisabled('coinbase')
    // }
    //trustwallet
    if (!window.trustwallet) {
        setWalletItemDisabled('trust')
    }

    const onMetaMaskAccountsChanged = (accounts) => {
        const account = accounts?.[0]
        walletInfo = {
            ...walletInfo,
            address: account
        }
        setAddress(walletInfo)
        ChatboxSDK?.processAccount({
            account: account
        })
        if (window.provider && account) {
            loadChatbox(window.provider)
        } else {
            loadChatboxBrowser()
        }
    }

    window.removeChatbox = function () {
        try {
            if (window.provider?.removeAllListeners) {
                window.provider.removeAllListeners()
            }
            if (window.provider?.off) {
                window.provider.off('accountsChanged', onMetaMaskAccountsChanged)
            }
            setAddress(null)
            loadChatboxBrowser()
        } catch (error) {
            console.error('Error removing iframe:', error)
        } finally {
            // ChatboxSDK.loadChatbox()
            setAddress(null)
        }
    }

    window.openChatBox = function () {
        const dom = document.getElementById('groupfi_btn')
        if (dom) {
            let openData = localStorage.getItem('trollbox.preference')
            try {
                openData = JSON.parse(openData)
            } catch (error) {
                openData = {}
            }
            if (openData?.isOpen) {
                return
            }
            var event = new MouseEvent('click', {
                view: window,
                bubbles: true,
                cancelable: true
            })
            dom.dispatchEvent(event)
        } else {
            showConnect()
        }
    }

    const loadChatboxBrowser = () => {
        ChatboxSDK.removeChatbox()
        ChatboxSDK.loadChatbox({
            theme: 'dark',
            isWalletConnected: false
        })
    }

    const loadChatbox = async (provider) => {
        window.provider = provider
        ChatboxSDK.removeChatbox()
        ChatboxSDK.loadChatbox({
            isWalletConnected: true,
            provider: provider,
            theme: 'dark'
        })
        // ChatboxSDK.loadChatbox({ walletType: 'metamask' })
        // ChatboxSDK.setWalletProvider(provider)
        // ChatboxSDK.request({
        //     method: 'setForMeGroups',
        //     params: {
        //         includes: [
        //             {
        //                 groupId: 'groupfiAnnouncement25f8059238b37fc4175a6581ea3f962059e4582ae9779fae8782551b314e228d'
        //             },
        //             {
        //                 groupId: 'groupfiEtherVisions1b51a2178f2fd6a8c436b924bec6a49d3b83e06f165303dff4604bed1fcc423e'
        //             },
        //             {
        //                 groupId: 'groupfiTOKENac3fc59f9142e741a1ae2f878187edf55d6ee7b2979e2d130375c589ca6488b8'
        //             }
        //         ],
        //         announcement: [
        //             {
        //                 groupId: 'groupfiAnnouncement25f8059238b37fc4175a6581ea3f962059e4582ae9779fae8782551b314e228d'
        //             }
        //         ]
        //     }
        // })
    }

    const getAccounts = async (provider, walletType, isAuto) => {
        if (!isAuto) {
            await provider.request({
                method: 'wallet_requestPermissions',
                params: [
                    {
                        eth_accounts: {}
                    }
                ]
            })
        }
        const accounts = await provider.request({ method: 'eth_requestAccounts' }).catch(() => {
            throw 'ConnectFailed'
        })

        const rawAccount = accounts?.[0]
        if (!rawAccount) {
            loadChatboxBrowser()
            throw new Error()
        }
        const account = rawAccount.toLowerCase()
        // if (!/^0x/i.test(account)) {
        //     Toastify({
        //         text: 'Chain not supported',
        //         duration: 3000,
        //         position: 'center'
        //     }).showToast()
        //     throw new Error()
        // }
        walletInfo = {
            address: account,
            walletType
        }
        setAddress(walletInfo)
        loadChatbox(provider)
        provider.on('accountsChanged', onMetaMaskAccountsChanged)
    }
    window.handleConnect = async (type, isAuto = false) => {
        window.removeChatbox()
        hideConnect()
        switch (type) {
            case 'metamask':
                getAccounts(MMSDK.getProvider(), type, isAuto)
                break
            case 'okx':
                getAccounts(window.okxwallet, type, isAuto)
                break
            case 'coinbase':
                const sdk = new CoinbaseWalletSDK({
                    appName: 'GroupFi.ai'
                })
                const provider = sdk.makeWeb3Provider()
                getAccounts(provider, type, isAuto)
                break
            case 'trust':
                getAccounts(window.trustwallet, type, isAuto)
                break
            default:
                loadChatboxBrowser()
                break
        }
    }

    window.addEventListener('chatbox-ready', function () {
        if (walletInfo?.address) {
            ChatboxSDK.processAccount({
                account: walletInfo.address
            })
        }
        ChatboxSDK.request({
            method: 'setGroups',
            params: {
                includes: [
                    {
                        groupId: 'groupfiadmin7ef7bd5f49843d162c869edc56c59ef73e123a872563cdca1f612267696ae3df'
                    },
                    {
                        groupId: 'groupfiGTESTcrab08181a9bbb45f85ce1399009e9bb0c9ad40d965cadd6db33b5b52e53d297998a'
                    },
                    {
                        groupId: 'groupfiGroupedApe3301f18083824e9c9a29093fa96de5ad18845a7d8b0c54b2237ea80aad98c9d4'
                    }
                ],
                announcement: [
                    {
                        groupId: 'groupfiadmin7ef7bd5f49843d162c869edc56c59ef73e123a872563cdca1f612267696ae3df'
                    }
                ]
            }
        })
    })

    handleConnect(walletInfo?.walletType, true)
}
